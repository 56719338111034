import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Button, Grid, MenuItem, Box, Paper } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

function App() {
  const [idToken, setIdToken] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [expiresIn, setExpiresIn] = useState('');
  const [tokenType, setTokenType] = useState('');
  const [email, setEmail] = useState('');
  const [formData, setFormData] = useState({
    nombre: '',
    edad: '',
    genero: '',
    estadoOrigen: '',
  });
  const [isFormLocked, setIsFormLocked] = useState(false);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    // Leer la URL y extraer los tokens
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.slice(1));
    const id_token = params.get('id_token');
    const access_token = params.get('access_token');
    const expires_in = params.get('expires_in');
    const token_type = params.get('token_type');

    if (id_token && access_token && token_type) {
      setIdToken(id_token);
      setAccessToken(access_token);
      setExpiresIn(expires_in);
      setTokenType(token_type);
      const idPayload = JSON.parse(atob(id_token.split('.')[1]));
      setEmail(idPayload.email);

      // Limpiar los tokens de la URL
      window.history.replaceState({}, document.title, window.location.pathname);
    } else if (!accessToken || !tokenType) {
      // Redirigir si no hay tokens
      window.location.href = 'https://universidad-latino-alexochoa.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=3f9nr55lpjm15nu38sgl34goov&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Funiversidad-latino-alexochoa.alexochoa.dev';
    }
  }, []);

  useEffect(() => {
    if (accessToken && tokenType && email) {
      // Realizar un GET al inicio para obtener datos previos
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://demo-latiino-api.alexochoa.dev/api/datos-demograficos?email=${email}`, {
            headers: {
              Authorization: `${tokenType} ${accessToken}`,
            },
          });
          if (response.data) {
            setFormData(response.data);
            setIsFormLocked(true);
          }
          // Realizar la consulta para obtener todos los datos
          await fetchAllData();
        } catch (error) {
          console.error('Error al obtener los datos:', error);
        }
      };
      fetchData();
    }
  }, [accessToken, tokenType, email]);

  const fetchAllData = async () => {
    try {
      const response = await axios.get('https://demo-latiino-api.alexochoa.dev/api/datos-demograficos-all', {
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        },
      });
      setAllData(response.data);
    } catch (error) {
      console.error('Error al obtener todos los datos:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.email = email;
    try {
      const response = await axios.post(
        'https://demo-latiino-api.alexochoa.dev/api/datos-demograficos',
        formData,
        {
          headers: {
            Authorization: `${tokenType} ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Datos enviados correctamente:', response.data);
      // Obtener todos los datos después de enviar la información
      await fetchAllData();
    } catch (error) {
      console.error('Error enviando los datos:', error);
    }
  };

  // Preparar datos para visualización de estadísticos
  const prepareChartData = () => {
    const genderCount = allData.reduce(
      (acc, curr) => {
        if (curr.genero === 'masculino') acc.masculino++;
        if (curr.genero === 'femenino') acc.femenino++;
        if (curr.genero === 'otro') acc.otro++;
        return acc;
      },
      { masculino: 0, femenino: 0, otro: 0 }
    );

    return {
      labels: ['Masculino', 'Femenino', 'Otro'],
      datasets: [
        {
          label: 'Distribución de Género',
          data: [genderCount.masculino, genderCount.femenino, genderCount.otro],
          backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(153, 102, 255, 0.2)'],
          borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)', 'rgba(153, 102, 255, 1)'],
          borderWidth: 1,
        },
      ],
    };
  };

  const prepareAgeChartData = () => {
    const ageGroups = allData.reduce((acc, curr) => {
      const age = parseInt(curr.edad, 10);
      if (age < 18) acc['< 18']++;
      else if (age >= 18 && age <= 35) acc['18-35']++;
      else if (age >= 36 && age <= 50) acc['36-50']++;
      else acc['> 50']++;
      return acc;
    }, { '< 18': 0, '18-35': 0, '36-50': 0, '> 50': 0 });

    return {
      labels: ['< 18', '18-35', '36-50', '> 50'],
      datasets: [
        {
          label: 'Distribución de Edad',
          data: [ageGroups['< 18'], ageGroups['18-35'], ageGroups['36-50'], ageGroups['> 50']],
          backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)'],
          borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)'],
          borderWidth: 1,
        },
      ],
    };
  };

  const prepareStateChartData = () => {
    const stateCount = allData.reduce((acc, curr) => {
      if (curr.estadoOrigen) {
        acc[curr.estadoOrigen] = (acc[curr.estadoOrigen] || 0) + 1;
      }
      return acc;
    }, {});

    return {
      labels: Object.keys(stateCount),
      datasets: [
        {
          label: 'Distribución por Estado de Origen',
          data: Object.values(stateCount),
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgba(255, 159, 64, 1)',
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <Container component={Paper} maxWidth="sm" sx={{ mt: 5, p: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Formulario de Datos Demográficos
      </Typography>
      <Typography variant="subtitle1" gutterBottom align="center">
        Correo: {email}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              required
              disabled={isFormLocked}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              label="Edad"
              name="edad"
              value={formData.edad}
              onChange={handleChange}
              required
              disabled={isFormLocked}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              label="Género"
              name="genero"
              value={formData.genero}
              onChange={handleChange}
              required
              disabled={isFormLocked}
            >
              <MenuItem value="">Seleccione una opción</MenuItem>
              <MenuItem value="masculino">Masculino</MenuItem>
              <MenuItem value="femenino">Femenino</MenuItem>
              <MenuItem value="otro">Otro</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              label="Estado de Origen"
              name="estadoOrigen"
              value={formData.estadoOrigen}
              onChange={handleChange}
              required
              disabled={isFormLocked}
            >
              <MenuItem value="">Seleccione un estado</MenuItem>
              <MenuItem value="aguascalientes">Aguascalientes</MenuItem>
              <MenuItem value="baja_california">Baja California</MenuItem>
              <MenuItem value="baja_california_sur">Baja California Sur</MenuItem>
              <MenuItem value="campeche">Campeche</MenuItem>
              <MenuItem value="cdmx">Ciudad de México</MenuItem>
              <MenuItem value="coahuila">Coahuila</MenuItem>
              <MenuItem value="colima">Colima</MenuItem>
              <MenuItem value="chiapas">Chiapas</MenuItem>
              <MenuItem value="chihuahua">Chihuahua</MenuItem>
              <MenuItem value="durango">Durango</MenuItem>
              <MenuItem value="guanajuato">Guanajuato</MenuItem>
              <MenuItem value="guerrero">Guerrero</MenuItem>
              <MenuItem value="hidalgo">Hidalgo</MenuItem>
              <MenuItem value="jalisco">Jalisco</MenuItem>
              <MenuItem value="estado_de_mexico">Estado de México</MenuItem>
              <MenuItem value="michoacan">Michoacán</MenuItem>
              <MenuItem value="morelos">Morelos</MenuItem>
              <MenuItem value="nayarit">Nayarit</MenuItem>
              <MenuItem value="nuevo_leon">Nuevo León</MenuItem>
              <MenuItem value="oaxaca">Oaxaca</MenuItem>
              <MenuItem value="puebla">Puebla</MenuItem>
              <MenuItem value="queretaro">Querétaro</MenuItem>
              <MenuItem value="quintana_roo">Quintana Roo</MenuItem>
              <MenuItem value="san_luis_potosi">San Luis Potosí</MenuItem>
              <MenuItem value="sinaloa">Sinaloa</MenuItem>
              <MenuItem value="sonora">Sonora</MenuItem>
              <MenuItem value="tabasco">Tabasco</MenuItem>
              <MenuItem value="tamaulipas">Tamaulipas</MenuItem>
              <MenuItem value="tlaxcala">Tlaxcala</MenuItem>
              <MenuItem value="veracruz">Veracruz</MenuItem>
              <MenuItem value="yucatan">Yucatán</MenuItem>
              <MenuItem value="zacatecas">Zacatecas</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} align="center">
            <Button variant="contained" color="primary" type="submit" disabled={isFormLocked}>
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Box>
      {allData.length > 0 && (
        <Box sx={{ mt: 5 }}>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Estadísticas de Datos Recopilados
          </Typography>
          <Bar data={prepareChartData()} />
          <Box sx={{ mt: 5 }}>
            <Bar data={prepareAgeChartData()} />
          </Box>
          <Box sx={{ mt: 5 }}>
            <Bar data={prepareStateChartData()} />
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default App;
